import {Link} from "gatsby";
import React from "react";
import "../../Forms/form.css";

const FORMSPARK_ACTION_URL = "https://submit-form.com/bbDqktZl";

export default function ContactForm() {
	return (
		<form className="form" method="POST" action={FORMSPARK_ACTION_URL}>
			<input
				type="hidden"
				name="_email.subject"
				value="¡Tienen un nuevo mensaje en tu web!"
			/>
			<input type="hidden" name="_email.template.title" value="Nuevo lead" />
			<input
				type="hidden"
				name="_redirect"
				value="https://dreamers.do/gracias"
			/>
			<label className="required" htmlFor="nombre" required>
				Nombre
			</label>
			<input type="text" required placeholder="¿Cómo te llamas?" />

			<label className="required" htmlFor="whatsapp" required>
				WhatsApp
			</label>
			<input type="tel" required placeholder="¿Cuál es tu número?" />
			<label className="required" htmlFor="correo" required autofocus>
				Correo
			</label>
			<input type="email" required placeholder="¿Cuál es tu correo?" />
			<label className="required" htmlFor="servicios" required>
				¿En qué puedo servirte?
			</label>
			<select required>
				<option value="Capacitaciones" selected>
					Capacitaciones
				</option>
				<option value="Asesorías empresariales">Asesorías empresariales</option>
				<option value="Consultoría de proyectos">
					Consultoría de proyectos
				</option>
				<option value="Plan de Comunicaciones">Plan de Comunicaciones</option>
				<option value="Asesoría Comercial">Asesoría Comercial</option>
				<option value="Asesoría Táctica">Asesoría Táctica</option>
				<option value="Planificación Estratégica y Operativa Empresarial">
					Planificación Estratégica y Operativa Empresarial
				</option>
				<option value="Estrategia de Comunicación Digital">
					Estrategia de Comunicación Digital
				</option>
				<option value="Voz para materiales publicitarios">
					Voz para materiales publicitarios
				</option>
				<option value="Coordinación de Eventos">Coordinación de Eventos</option>
				<option value="Relaciones públicas">Relaciones públicas</option>
				<option value="Maestría de ceremonias">Maestría de ceremonias</option>
				<option value="Organización de Eventos">Organización de Eventos</option>
			</select>
			<label className="required" htmlFor="descripcion" required>
				¿Qué puedo ayudarte a lograr?
			</label>
			<textarea
				placeholder="¡Cuéntame más sobre tus necesidades y objetivos!"
				required
			/>
			<div className="consentimiento-container">
				<input
					type="radio"
					id="consentimiento"
					className="consentimiento-item"
					name="consentimiento"
					value="consentimiento"
					required
				/>
				<small className="consentimiento-item">
					Estoy de acuerdo con los{" "}
					<Link to="/aviso-legal/terminos">Términos y condiciones</Link> y la{" "}
					<Link to="/aviso-legal/privacidad">Política de Privacidad</Link>.
				</small>
			</div>
			<button type="submit" className="form-btn">
				Enviar
			</button>
		</form>
	);
}

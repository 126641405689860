import React from "react";
import {Link} from "gatsby";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import "./footer.css";

const Footer = () => {
	return (
		<footer className="anchocompleto fondogrisfc">
			<section className="contenedoranchocompleto">
				<section className="filadoscol">
					<div className="doscolumnas-25">
						<div className="infocontactofooter peq">
							<p className="peq">
								<span className="negrita">Whatsapp</span>: +1 829-554-1118
							</p>
							<p className="peq">
								<span className="negrita">Correo</span>:
								dreamersgrouprd@gmail.com
							</p>
							<SocialIcons />
						</div>
					</div>
					<div className="doscolumnas-75 footer-nav-links">
						<Link to="/#about" className="footer-nav-link">
							About
						</Link>
						<Link to="/#servicios" className="footer-nav-link">
							Servicios
						</Link>
						<Link to="/#faq" className="footer-nav-link">
							Preguntas frecuentes
						</Link>
						<Link to="/aviso-legal" className="footer-nav-link">
							Aviso legal
						</Link>
					</div>
				</section>
				<section className="filaunacol" id="creditos">
					<div className="unacolumna">
						<p className="centrado">
							&copy; Dreamers {new Date().getFullYear()}. Todos los derechos
							reservados.
						</p>
					</div>
				</section>
			</section>
		</footer>
	);
};

export default Footer;

import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../../assets/css/main.css";

const Layout = ({children}) => {
	return (
		<html lang={"es"}>
			<head>
				<meta charSet="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</head>
			<Navbar />
			<main className="pagina">{children}</main>
			<Footer />
		</html>
	);
};

export default Layout;

import React from "react";
import {BsInstagram, BsFacebook} from "react-icons/bs";
import "./social.css";

const SocialIcons = () => {
	return (
		<section className="social-icons">
			<a href="https://www.instagram.com/dreamersdr">
				<div className="one-social-icon">
					<BsInstagram />
				</div>
			</a>
			<a href="https://www.facebook.com/dreamersdr">
				<div className="one-social-icon">
					<BsFacebook />
				</div>
			</a>
		</section>
	);
};

export default SocialIcons;

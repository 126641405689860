import React, {Component} from "react";
import ContactForm from "../Forms/ContactForm/ContactForm";
import ModalContacto from "./ModalContacto";
import "./modals.css";

class BotonModalContacto extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
		this.showModal = this.showModal.bind(this);
		this.hideModal = this.hideModal.bind(this);
	}

	showModal = () => {
		this.setState({show: true});
	};

	hideModal = () => {
		this.setState({show: false});
	};
	render() {
		return (
			<section className="">
				<div className="">
					<ModalContacto show={this.state.show} handleClose={this.hideModal}>
						<ContactForm />
					</ModalContacto>
					<button type="button" className="btn" onClick={this.showModal}>
						Contactar
					</button>
				</div>
			</section>
		);
	}
}

export default BotonModalContacto;

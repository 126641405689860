import React from "react";
import {BsX} from "react-icons/bs";
import "./modals.css";

const ModalContacto = ({handleClose, show, children}) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	return (
		<div className={showHideClassName}>
			<section className="modal-main">
				<div className="modal-header">
					<button
						class="close-modal-symbol no-btn"
						onClick={handleClose}
						onKeyDown={handleClose}
					>
						<BsX />
					</button>
					<h2 className="centrado paddingtopdefault-small">
						Contáctanos, es gratis
					</h2>
				</div>
				{children}
			</section>
		</div>
	);
};

export default ModalContacto;

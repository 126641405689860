import React, {useState} from "react";
import {Link} from "gatsby";
import {BsList} from "react-icons/bs";
import "./nav.css";
import BotonModalContacto from "../Modales/BotonModalContacto";
import {StaticImage} from "gatsby-plugin-image";

const Navbar = () => {
	const [show, setShow] = useState(false);
	return (
		<nav className="navbar">
			<div className="nav-center">
				<div className="nav-header">
					<Link to="/" className="nav-logo">
						<StaticImage
							src="../../assets/images/Logo/LogoDreamers.png"
							alt="Logo Dreamers"
							width={350}
						/>
					</Link>
					<button className="nav-btn" onClick={() => setShow(!show)}>
						<BsList />
					</button>
				</div>
				<div
					className={show ? "nav-links show-links" : "nav-links"}
					id="links-container"
				>
					<Link
						to="/#about"
						className="nav-link"
						activeClassName="active-link"
						onClick={() => setShow(false)}
					>
						About
					</Link>
					<Link
						to="/#servicios"
						className="nav-link"
						activeClassName="active-link"
						onClick={() => setShow(false)}
					>
						Servicios
					</Link>
					<Link
						to="/#faq"
						className="nav-link"
						activeClassName="active-link"
						onClick={() => setShow(false)}
					>
						Preguntas frecuentes
					</Link>
					<div className="nav-link contact-link">
						<BotonModalContacto onClick={() => setShow(false)} />
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
